<template>
  <b-card>
    <card-title title="Параметры отчетности" />
    <div class="d-flex row-group">
      <div class="select-block">
        <label>Отчетный период</label>
        <i-select
          style="max-width: 170px"
          :value="getDeclaration?.quarter"
          placeholder="Отчетный период"
          filterable
          @input="inputPeriod"
        >
          <i-option
            v-for="item in period"
            :key="item.id"
            :value="item.id"
          >
            <label>Отчетный период</label>
            {{ item.name }}
          </i-option>
        </i-select>
      </div>
      <div class="select-block">
        <label>Форма отчетности</label>
        <i-select
          style="max-width: 170px"
          :value="getDeclaration?.type"
          placeholder="Все магазины"
          filterable
          @input="inputType"
        >
          <i-option
            v-for="item in form"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </i-option>
        </i-select>
      </div>
    </div>
  </b-card>
</template>

<script>
  import CardTitle from '@/views/analytics/components/declaration/CardTitle'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'AboutParams',
    components: { CardTitle },
    data() {
      return {
        form: [
          { id: 'first', name: 'Первичная' },
          { id: 'corrective', name: 'Корректирующая' }
        ],
        period: [
          { id: 'first', name: 'I квартал 2023г.' },
          { id: 'second', name: 'II квартал 2023г' },
          { id: 'third', name: 'III квартал 2023г' },
          { id: 'fourth', name: 'IV квартал 2023г' }
        ]
      }
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration'
      })
    },
    methods: {
      ...mapActions({
        setDeclaration: 'analytics/setDeclaration'
      }),
      inputPeriod(value) {
        this.setDeclaration({ ...this.getDeclaration, quarter: value })
        this.$emit('updateSelect')
      },
      inputType(value) {
        this.setDeclaration({ ...this.getDeclaration, type: value })
        this.$emit('updateSelect')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-block {
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-left: 16px;
    }
  }

  ::v-deep() {
    .item {
      margin-right: 16px;
    }
  }

  .row-group {
    margin-top: 16px;
  }
</style>
