<template>
  <b-card>
    <card-title title="Магазины" />
    <h5 v-if="!getBranches?.length">Здесь появятся магазины выбранного юрдического лица</h5>
    <resizable-table
      v-if="getBranches?.length"
      style="margin-top: 10px"
      :default_fields="fields"
      :items="getBranches"
      table_name="alco-entity"
    >
      <template #head_id="data">
        <div style="position: relative">
          <e-checkbox
            position="center"
            :checked="all"
            :value="data.value"
            @click="all = !all"
          />
        </div>
      </template>
      <template #body_id="data">
        <div style="position: relative">
          <e-checkbox
            position="center"
            :checked="checked(data.value)"
            :value="data.value"
            @click="selectBranch(data.value)"
          />
        </div>
      </template>
      <template #body_branch="{ item }">
        {{ item.name }}
      </template>
      <template #body_address="{ item }">
        {{ item.address?.country }}, {{ item.address?.city }}, {{ item.address?.street }}, {{ item.address?.house }},
      </template>
    </resizable-table>
  </b-card>
</template>

<script>
  import CardTitle from '@/views/analytics/components/declaration/CardTitle'
  import ResizableTable from '@/components/ResizableTable'
  import ECheckbox from '@/components/ECheckbox'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AboutShops',
    components: { ECheckbox, ResizableTable, CardTitle },
    data() {
      return {
        all: false,
        selected: [],
        fields: [
          {
            label: '',
            key: 'id',
            width: 50,
            checked: true
          },
          {
            label: 'Магазин',
            key: 'branch',
            width: 180,
            checked: true
          },
          {
            label: 'Адрес магазина',
            key: 'address',
            width: 180,
            checked: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration',
        getBranches: 'analytics/getBranches'
      })
    },
    watch: {
      all() {
        if (this.all) {
          this.selected = this.getBranches.map((obj) => obj.id)
          return
        }
        this.selected = []
      }
    },

    methods: {
      selectBranch(val) {
        if (!this.selected.includes(val)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj !== val)
        }
      },
      checked(val) {
        return !!this.selected.find((obj) => obj === val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .alco-entity {
    &::-webkit-scrollbar-thumb {
      background: #bebebe;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  }
</style>
