var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-block"
  }, [_c('b', {
    staticClass: "title"
  }, [_vm._v("Пиво и пивные напитки, первичная за 3 квартал 2023 ")]), _c('div', {
    staticClass: "download"
  }, [_c('p', [_vm._v("Файл")]), _c('b-button', {
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.download
    }
  }, [_c('document-svg', {
    staticStyle: {
      "margin-right": "6px"
    }
  }), _c('p', {
    staticStyle: {
      "padding-right": "10px",
      "margin": "0"
    }
  }, [_vm._v("declaration_xml")]), _c('div', {
    staticClass: "vertival-line"
  }), _c('download-svg', {
    staticStyle: {
      "margin-left": "10px"
    }
  })], 1)], 1), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "time-block"
  }, [_c('p', [_vm._v("Дата и время форматирования")]), _c('b', [_vm._v(_vm._s(_vm.convertData()))])]), _c('div', {
    staticClass: "line"
  }), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "region"
  }, [_c('p', [_vm._v("Регион, принимающий декларацию")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('b', [_vm._v("Свердловская область")]), _c('p', {
    staticClass: "region__change"
  }, [_vm._v("Изменить")])])]);

}]

export { render, staticRenderFns }