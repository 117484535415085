var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('alco-product-list-modal'), _c('b-card', {
    staticClass: "item"
  }, [_c('card-title', {
    attrs: {
      "title": "Магазины"
    }
  }), _c('resizable-table', {
    attrs: {
      "default_fields": _vm.fields,
      "items": _vm.getSpecifications,
      "table_name": "alco-declarations-table"
    },
    scopedSlots: _vm._u([{
      key: "body_branch",
      fn: function (data) {
        var _data$item, _data$item$branch;

        return [_vm._v(" " + _vm._s((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$branch = _data$item.branch) === null || _data$item$branch === void 0 ? void 0 : _data$item$branch.name) + " ")];
      }
    }, {
      key: "body_product",
      fn: function () {
        return [_c('div', {
          staticClass: "list-text",
          on: {
            "click": _vm.show_list
          }
        }, [_vm._v(" список ")])];
      },
      proxy: true
    }, {
      key: "body_status",
      fn: function () {
        return [_c('b-badge', {
          staticStyle: {
            "padding": "10px"
          },
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" Нет проблем ")])];
      },
      proxy: true
    }, {
      key: "body_remainder_request",
      fn: function () {
        return [_vm._v(" - ")];
      },
      proxy: true
    }, {
      key: "body_consumption",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.outgoing) + " ")];
      }
    }, {
      key: "body_remainder_start",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.begin) + " ")];
      }
    }, {
      key: "body_remainder_end",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.end) + " ")];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }