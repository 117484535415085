var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_11862_137363)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M15.701 4.37316L11.4823 0.154512C11.3838 0.0560039 11.2494 0 11.1094 0H3.72656C2.85423 0 2.14453 0.709699 2.14453 1.58203V16.418C2.14453 17.2903 2.85423 18 3.72656 18H14.2734C15.1458 18 15.8555 17.2903 15.8555 16.418V4.74609C15.8555 4.6023 15.7951 4.46727 15.701 4.37316ZM11.6367 1.80046L14.055 4.21875H12.1641C11.8733 4.21875 11.6367 3.98218 11.6367 3.69141V1.80046ZM14.2734 16.9453H3.72656C3.43579 16.9453 3.19922 16.7087 3.19922 16.418V1.58203C3.19922 1.29125 3.43579 1.05469 3.72656 1.05469H10.582V3.69141C10.582 4.56374 11.2917 5.27344 12.1641 5.27344H14.8008V16.418C14.8008 16.7087 14.5642 16.9453 14.2734 16.9453Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.1641 7.45312H5.83594C5.5447 7.45312 5.30859 7.68923 5.30859 7.98047C5.30859 8.2717 5.5447 8.50781 5.83594 8.50781H12.1641C12.4553 8.50781 12.6914 8.2717 12.6914 7.98047C12.6914 7.68923 12.4553 7.45312 12.1641 7.45312Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.1641 9.5625H5.83594C5.5447 9.5625 5.30859 9.79861 5.30859 10.0898C5.30859 10.3811 5.5447 10.6172 5.83594 10.6172H12.1641C12.4553 10.6172 12.6914 10.3811 12.6914 10.0898C12.6914 9.79861 12.4553 9.5625 12.1641 9.5625Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.1641 11.6719H5.83594C5.5447 11.6719 5.30859 11.908 5.30859 12.1992C5.30859 12.4905 5.5447 12.7266 5.83594 12.7266H12.1641C12.4553 12.7266 12.6914 12.4905 12.6914 12.1992C12.6914 11.908 12.4553 11.6719 12.1641 11.6719Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.0547 13.7812H5.83594C5.5447 13.7812 5.30859 14.0174 5.30859 14.3086C5.30859 14.5998 5.5447 14.8359 5.83594 14.8359H10.0547C10.3459 14.8359 10.582 14.5998 10.582 14.3086C10.582 14.0174 10.3459 13.7812 10.0547 13.7812Z",
      "fill": "black"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_11862_137363"
    }
  }, [_c('rect', {
    attrs: {
      "width": "18",
      "height": "18",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }