<template>
  <div>
    <about-params
      class="item"
      @updateSelect="updateSelect"
    />
    <about-entity
      class="item"
      @updateSelect="updateSelect"
    />
    <about-shops class="item" />
  </div>
</template>

<script>
  import AboutParams from '@/views/analytics/components/declaration/AboutParams'
  import AboutEntity from '@/views/analytics/components/declaration/AboutEntity'
  import AboutShops from '@/views/analytics/components/declaration/AboutShops'
  import { mapGetters } from 'vuex'

  export default {
    name: 'About',
    components: { AboutShops, AboutEntity, AboutParams },
    props: {
      branches: {
        type: Array
      }
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration'
      })
    },
    methods: {
      updateSelect() {
        this.$emit('updateSelect')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    margin-top: 16px;
  }
</style>
