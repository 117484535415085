var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('about-params', {
    staticClass: "item",
    on: {
      "updateSelect": _vm.updateSelect
    }
  }), _c('about-entity', {
    staticClass: "item",
    on: {
      "updateSelect": _vm.updateSelect
    }
  }), _c('about-shops', {
    staticClass: "item"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }