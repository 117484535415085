<template>
  <div>
    <b-modal
      id="alco-product-list-modal"
      hide-footer
      title="Декларация"
      centered
      size="xl"
    >
      <b-button
        class="mb-2"
        variant="gray"
        @click="chooseFiles"
      >
        Загрузить первичные данные из файла
      </b-button>
      <input
        id="fileUpload"
        ref="doc"
        type="file"
        hidden
        @change="readFile()"
      />
      <resizable-table
        :items="items"
        :default_fields="fields_products"
        table_name="alco-declarations-products-table"
      >
        <template #head_id>
          <e-checkbox position="center" />
        </template>
        <template #body_id>
          <div style="position: relative">
            <e-checkbox position="center" />
          </div>
        </template>
        <template #body_manufacturer="{ item }">
          {{ item?.manufacturer?.name }}
        </template>
        <template #body_start_remainder="{ item }">
          <table-row-input
            :value="item.start_remainder"
            :fixed="false"
            @handle-input="(val) => setInput(val, item)"
          />
        </template>
      </resizable-table>
    </b-modal>
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable.vue'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import ECheckbox from '@/components/ECheckbox'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'AlcoProductListModal',
    components: {
      ECheckbox,
      ResizableTable,
      TableRowInput
    },
    apollo: {
      DeclarationSpecifications: {
        query: require('../../gql/DeclarationSpecifications.graphql'),
        variables() {
          return {
            input: {
              head: this.$route.params.id,
              pagination: {
                skip: 0,
                take: 20
              }
            }
          }
        },
        result({ data }) {
          if (data.DeclarationSpecifications) {
            this.setSpecifications(data?.DeclarationSpecifications)
            this.items = data.DeclarationSpecifications.map((obj) => {
              return obj.specifications
            })
            this.items = [].concat.apply([], this.items)
          } else {
            this.setSpecifications([])
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        getSpecifications: 'analytics/getSpecifications'
      })
    },
    methods: {
      ...mapActions({
        setSpecifications: 'analytics/setSpecifications'
      }),
      async setInput(value, spec) {
        spec.start_remainder = value
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/UpdateSpecDeclaration.graphql'),
          variables: {
            input: {
              id: spec.id,
              start_remainder: value
            }
          }
        })

        spec.income = data.UpdateSpecDeclaration.income
        spec.outgoing = data.UpdateSpecDeclaration.outgoing
        spec.income_another = data.UpdateSpecDeclaration.income_another
        spec.outgoing_another = data.UpdateSpecDeclaration.outgoing_another
      },
      async readFile() {
        this.file = this.$refs.doc.files[0]
        const reader = new FileReader()
        reader.onload = async (res) => {
          const iconv = require('iconv-lite')
          const encodedData = iconv.decode(res.target.result, 'win1251')
          await this.$apollo.mutate({
            mutation: require('../../gql/LoadOldXml.graphql'),
            variables: {
              input: {
                id: this.$route.params.id,
                old_xml: encodedData.toString()
              }
            }
          })
          await this.$apollo.queries.DeclarationSpecifications.refetch()
          this.content = res.target.result
        }

        reader.onerror = (err) => console.error(err)
        // reader.readAsText(this.file)
        reader.readAsBinaryString(this.file)
      },
      chooseFiles() {
        document.getElementById('fileUpload').click()
      }
    },
    data() {
      return {
        items: [],
        file: null,
        content: null,
        fields_products: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'code',
            label: 'Код',
            checked: true,
            width: 50
          },
          {
            key: 'manufacturer',
            label: 'Контрагент',
            checked: true,
            width: 260
          },
          {
            key: 'start_remainder',
            label: 'Остаток на начало',
            checked: true,
            width: 190
          },
          {
            key: 'income',
            label: 'Поступление ШТ/Дал',
            checked: true,
            width: 200
          },
          {
            key: 'incoming',
            label: 'Поступления',
            checked: true,
            width: 460,
            children: [
              {
                key: 'income_import',
                label: 'Импорт',
                checked: true,
                width: 115
              },
              {
                key: 'income_purchase',
                label: 'Закуп',
                checked: true,
                width: 115
              },
              {
                key: 'income_return',
                label: 'Возврат',
                checked: true,
                width: 115
              },
              {
                key: 'income_movement',
                label: 'Перемещение',
                checked: true,
                width: 115
              },
              {
                key: 'income_another',
                label: 'Прочее',
                checked: true,
                width: 115
              }
            ]
          },
          {
            key: 'outgoing_field',
            label: 'Расход',
            checked: true,
            width: 460,
            children: [
              {
                key: 'outgoing_sales',
                label: 'Закуп',
                checked: true,
                width: 115
              },
              {
                key: 'outgoing_return',
                label: 'Возврат',
                checked: true,
                width: 115
              },
              {
                key: 'outgoing_movement',
                label: 'Перемещение',
                checked: true,
                width: 115
              },
              {
                key: 'outgoing_another',
                label: 'Прочее',
                checked: true,
                width: 115
              }
            ]
          },
          {
            key: 'end_remainder',
            label: 'Остаток на конец',
            width: 200,
            checked: true
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .modal-body {
      overflow-y: hidden !important;
    }
    .modal-dialog,
    .modal-content {
      /* 80% of window height */
      height: 80%;
    }
    .card {
      height: calc(100% - 60px) !important;
    }

    .alco-declarations-products-table {
      &::-webkit-scrollbar-thumb {
        background: #bebebe;
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }

    .modal-body {
      /* 100% = dialog height, 120px = header + footer */
      height: 100%;
      overflow-y: scroll;
    }
  }
</style>
