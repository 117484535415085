<template>
  <b-card class="d-flex flex-column">
    <card-title
      style="display: block"
      title="Юридическое лицо"
    />
    <i-select
      class="row-group"
      :value="getDeclaration.entity"
      placeholder="Выберите юр. лицо"
      filterable
      @input="input"
    >
      <i-option
        v-for="item in entities"
        :key="item.id"
        :value="item.id"
      >
        {{ item.name }}
      </i-option>
    </i-select>
  </b-card>
</template>

<script>
  import CardTitle from '@/views/analytics/components/declaration/CardTitle'
  import { EntityModel } from '@/models/entity.model'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'AboutEntity',
    apollo: {
      Entities: {
        query: require('../../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entities = data.Entities?.map((el) => new EntityModel(el))
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    components: { CardTitle },
    data() {
      return {
        entities: []
      }
    },
    mounted() {
      this.selected = this.getDeclaration.entity
    },
    updated() {
      this.selected = this.getDeclaration.entity
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration'
      })
    },
    methods: {
      ...mapActions({
        setDeclaration: 'analytics/setDeclaration'
      }),
      input(value) {
        this.setDeclaration({ ...this.getDeclaration, entity: value })
        this.$emit('updateSelect')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .row-group {
    margin-top: 16px;
    max-width: 260px;
  }
</style>
