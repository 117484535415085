<template>
  <div style="flex: 1">
    <alco-product-list-modal />
    <b-card class="item">
      <card-title title="Магазины" />
      <resizable-table
        :default_fields="fields"
        :items="getSpecifications"
        table_name="alco-declarations-table"
      >
        <template #body_branch="data">
          {{ data.item?.branch?.name }}
        </template>
        <template #body_product>
          <div
            class="list-text"
            @click="show_list"
          >
            список
          </div>
        </template>
        <template #body_status>
          <b-badge
            style="padding: 10px"
            variant="success"
          >
            Нет проблем
          </b-badge>
        </template>
        <template #body_remainder_request=""> - </template>
        <template #body_consumption="data">
          {{ data.item.outgoing }}
        </template>
        <template #body_remainder_start="data">
          {{ data.item.begin }}
        </template>
        <template #body_remainder_end="data">
          {{ data.item.end }}
        </template>
      </resizable-table>
    </b-card>
  </div>
</template>

<script>
  import CardTitle from '@/views/analytics/components/declaration/CardTitle'
  import ResizableTable from '@/components/ResizableTable'
  import { mapGetters } from 'vuex'
  import AlcoProductListModal from '@/views/analytics/components/declaration/AlcoProductListModal.vue'

  export default {
    name: 'Move',
    components: { AlcoProductListModal, ResizableTable, CardTitle },
    data() {
      return {
        items: [],
        fields: [
          {
            label: 'Магазин',
            key: 'branch',
            width: 180,
            checked: true
          },
          {
            label: 'Товары',
            key: 'product',
            width: 105,
            checked: true
          },
          {
            label: 'Статус',
            key: 'status',
            width: 153,
            checked: true
          },
          {
            label: 'Остаток заявленный',
            key: 'remainder_request',
            width: 195,
            checked: true
          },
          {
            label: 'Остаток на начало',
            key: 'remainder_start',
            width: 195,
            checked: true
          },
          {
            label: 'Поступление ШТ/Дал',
            key: 'incoming',
            width: 195,
            checked: true
          },
          {
            label: 'Расходы ШТ/Дал',
            key: 'consumption',
            width: 195,
            checked: true
          },
          {
            label: 'Остаток на конец',
            key: 'remainder_end',
            width: 195,
            checked: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration',
        getBranches: 'analytics/getBranches',
        getSpecifications: 'analytics/getSpecifications'
      })
    },
    methods: {
      show_list() {
        return this.$bvModal.show('alco-product-list-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .alco-declarations-table {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bcbcbc;
      border-radius: 10px;
    }
  }

  .item {
    margin-top: 16px;
    flex: 1;
  }

  .list-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    text-decoration-line: underline;

    color: #313131;
  }
</style>
