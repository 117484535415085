var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-aclo-declaration"
  }, [_c('b-tabs', {
    staticClass: "d-flex flex-column flex-grow-1 flex-shrink-1",
    attrs: {
      "active-nav-item-class": "font-weight-bold"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "1. О декларации"
    }
  }, [_c('about', {
    attrs: {
      "branches": _vm.branches
    },
    on: {
      "updateSelect": _vm.updateSelectOptions
    }
  })], 1), _c('b-tab', {
    class: {
      'd-flex': _vm.tabIndex === 1,
      'flex-column': _vm.tabIndex === 1,
      'flex-grow-1': _vm.tabIndex === 1,
      'flex-shrink-1': _vm.tabIndex === 1
    },
    attrs: {
      "disabled": !_vm.getDeclaration.entity,
      "title": "2. Движение продукции"
    }
  }, [_vm.tabIndex === 1 ? _c('move', {
    staticClass: "d-flex flex-column flex: flex-shrink-1 flex-grow-1"
  }) : _vm._e()], 1), _c('b-tab', {
    class: {
      'd-flex': _vm.tabIndex === 2,
      'flex-column': _vm.tabIndex === 2,
      'flex-grow-1': _vm.tabIndex === 2,
      'flex-shrink-1': _vm.tabIndex === 2
    },
    attrs: {
      "disabled": !_vm.getDeclaration.entity,
      "title": "3. Отправка декларации"
    }
  }, [_vm.tabIndex === 2 ? _c('send', {
    staticClass: "d-flex flex-column flex: flex-shrink-1 flex-grow-1",
    attrs: {
      "entity": _vm.entity
    }
  }) : _vm._e()], 1)], 1), _c('tab-control-button', {
    attrs: {
      "tab_index": _vm.tabIndex
    },
    on: {
      "back": _vm.back,
      "next": _vm.next
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }