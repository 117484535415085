var render = function render(){
  var _vm$getBranches, _vm$getBranches2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('card-title', {
    attrs: {
      "title": "Магазины"
    }
  }), !((_vm$getBranches = _vm.getBranches) !== null && _vm$getBranches !== void 0 && _vm$getBranches.length) ? _c('h5', [_vm._v("Здесь появятся магазины выбранного юрдического лица")]) : _vm._e(), (_vm$getBranches2 = _vm.getBranches) !== null && _vm$getBranches2 !== void 0 && _vm$getBranches2.length ? _c('resizable-table', {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "default_fields": _vm.fields,
      "items": _vm.getBranches,
      "table_name": "alco-entity"
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.all,
            "value": data.value
          },
          on: {
            "click": function ($event) {
              _vm.all = !_vm.all;
            }
          }
        })], 1)];
      }
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.checked(data.value),
            "value": data.value
          },
          on: {
            "click": function ($event) {
              return _vm.selectBranch(data.value);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_branch",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "body_address",
      fn: function (_ref2) {
        var _item$address, _item$address2, _item$address3, _item$address4;

        var item = _ref2.item;
        return [_vm._v(" " + _vm._s((_item$address = item.address) === null || _item$address === void 0 ? void 0 : _item$address.country) + ", " + _vm._s((_item$address2 = item.address) === null || _item$address2 === void 0 ? void 0 : _item$address2.city) + ", " + _vm._s((_item$address3 = item.address) === null || _item$address3 === void 0 ? void 0 : _item$address3.street) + ", " + _vm._s((_item$address4 = item.address) === null || _item$address4 === void 0 ? void 0 : _item$address4.house) + ", ")];
      }
    }], null, false, 2761748068)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }