var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "d-flex flex-column"
  }, [_c('card-title', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "title": "Юридическое лицо"
    }
  }), _c('i-select', {
    staticClass: "row-group",
    attrs: {
      "value": _vm.getDeclaration.entity,
      "placeholder": "Выберите юр. лицо",
      "filterable": ""
    },
    on: {
      "input": _vm.input
    }
  }, _vm._l(_vm.entities, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }