<template>
  <div class="new-aclo-declaration">
    <b-tabs
      v-model="tabIndex"
      class="d-flex flex-column flex-grow-1 flex-shrink-1"
      active-nav-item-class="font-weight-bold"
    >
      <b-tab title="1. О декларации">
        <about
          :branches="branches"
          @updateSelect="updateSelectOptions"
        />
      </b-tab>
      <b-tab
        :disabled="!getDeclaration.entity"
        :class="{
          'd-flex': tabIndex === 1,
          'flex-column': tabIndex === 1,
          'flex-grow-1': tabIndex === 1,
          'flex-shrink-1': tabIndex === 1
        }"
        title="2. Движение продукции"
      >
        <move
          v-if="tabIndex === 1"
          class="d-flex flex-column flex: flex-shrink-1 flex-grow-1"
        />
      </b-tab>
      <b-tab
        :disabled="!getDeclaration.entity"
        :class="{
          'd-flex': tabIndex === 2,
          'flex-column': tabIndex === 2,
          'flex-grow-1': tabIndex === 2,
          'flex-shrink-1': tabIndex === 2
        }"
        title="3. Отправка декларации"
      >
        <send
          v-if="tabIndex === 2"
          :entity="entity"
          class="d-flex flex-column flex: flex-shrink-1 flex-grow-1"
        />
      </b-tab>
    </b-tabs>
    <tab-control-button
      :tab_index="tabIndex"
      @back="back"
      @next="next"
    />
  </div>
</template>

<script>
  import About from '@/views/analytics/components/declaration/About'
  import Move from '@/views/analytics/components/declaration/Move'
  import Send from '@/views/analytics/components/declaration/Send'
  import TabControlButton from '@/views/analytics/pages/TabControlButton'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'NewAlcoDeclaration',
    apollo: {
      Declaration: {
        query: require('../gql/GetDeclaration.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.params.id
            },
            pagination: {
              skip: 0,
              take: 20
            }
          }
        },
        result({ data }) {
          const { entity, type, quarter } = data?.Declaration
          this.setDeclaration({
            entity: entity?.id,
            type: type || 'first',
            quarter: quarter || 'first'
          })
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    components: { TabControlButton, Send, Move, About },
    data() {
      return {
        tabIndex: 0,
        items: [],
        branches: [],
        entity: null
      }
    },
    watch: {
      'getDeclaration.entity'() {
        if (this.getDeclaration.entity) {
          this.getEntity()
        }
      }
    },
    mounted() {
      if (this.getDeclaration.entity) {
        this.getEntity()
      }
    },
    beforeDestroy() {
      this.setDeclaration({ entity: '', type: 'first', quarter: 'first' })
      this.setBranches([])
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration',
        getBranches: 'analytics/getBranches'
      })
    },
    methods: {
      ...mapActions({
        setDeclaration: 'analytics/setDeclaration',
        setBranches: 'analytics/setBranches'
      }),
      async getEntity() {
        const { data } = await this.$apollo.query({
          query: require('../gql/Entity.graphql'),
          variables: {
            id: this.getDeclaration.entity
          }
        })
        this.entity = data?.Entity
        if (data?.Entity.branches) {
          this.setBranches(data?.Entity.branches)
          this.branches = data?.Entity.branches
        } else {
          this.setBranches([])
          this.branches = []
        }
      },
      updateSelectOptions() {
        this.updateDeclaration()
      },
      updateDeclaration() {
        this.$apollo.mutate({
          mutation: require('../gql/UpdateDeclaration.graphql'),
          variables: {
            input: {
              id: this.$route.params.id,
              entity: this.getDeclaration.entity,
              type: this.getDeclaration.type,
              quarter: this.getDeclaration.quarter
            }
          }
        })
      },
      async fill() {
        await this.$apollo.mutate({
          mutation: require('../gql/FillDeclaration.graphql'),
          variables: {
            input: {
              id: this.$route.params.id
            }
          }
        })
      },
      back() {
        this.tabIndex -= 1
      },
      async next() {
        if (!this.getDeclaration.entity) {
          this.$noty.error('Добавьте юр. лицо чтобы перейти к следующему шагу')
          return
        }
        if (this.tabIndex === 0) {
          await this.fill()
          this.tabIndex += 1
          return
        }
        if (this.tabIndex === 1) {
          this.tabIndex += 1
          return
        }
        if (this.tabIndex === 2) {
          this.$router.push({ name: 'analytics.alco-declaration' })
          return
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .new-aclo-declaration {
    max-width: 1150px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  ::v-deep .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
