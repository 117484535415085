var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "alco-product-list-modal",
      "hide-footer": "",
      "title": "Декларация",
      "centered": "",
      "size": "xl"
    }
  }, [_c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "gray"
    },
    on: {
      "click": _vm.chooseFiles
    }
  }, [_vm._v(" Загрузить первичные данные из файла ")]), _c('input', {
    ref: "doc",
    attrs: {
      "id": "fileUpload",
      "type": "file",
      "hidden": ""
    },
    on: {
      "change": function ($event) {
        return _vm.readFile();
      }
    }
  }), _c('resizable-table', {
    attrs: {
      "items": _vm.items,
      "default_fields": _vm.fields_products,
      "table_name": "alco-declarations-products-table"
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "position": "center"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('e-checkbox', {
          attrs: {
            "position": "center"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body_manufacturer",
      fn: function (_ref) {
        var _item$manufacturer;

        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$manufacturer = item.manufacturer) === null || _item$manufacturer === void 0 ? void 0 : _item$manufacturer.name) + " ")];
      }
    }, {
      key: "body_start_remainder",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('table-row-input', {
          attrs: {
            "value": item.start_remainder,
            "fixed": false
          },
          on: {
            "handle-input": function (val) {
              return _vm.setInput(val, item);
            }
          }
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }