var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_11862_137367)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.76523 3.24365H8.36412C8.03766 3.24365 7.77302 3.5083 7.77302 3.83476C7.77302 4.16121 8.03766 4.42586 8.36412 4.42586H9.76523C10.3579 4.42586 10.8176 4.72831 10.8176 4.98879V10.2313C10.8176 10.4916 10.3579 10.7944 9.76523 10.7944H2.23437C1.64189 10.7944 1.18221 10.4916 1.18221 10.2313V4.98938C1.18221 4.7289 1.64189 4.42625 2.23437 4.42625H3.63529C3.96175 4.42625 4.22639 4.16161 4.22639 3.83515C4.22639 3.50869 3.96175 3.24405 3.63529 3.24405H2.23437C0.981429 3.24405 0 4.01071 0 4.98938V10.2315C0 11.2104 0.981429 11.9766 2.23437 11.9766H9.76563C11.0182 11.9766 12 11.21 12 10.2315V4.98938C11.9998 4.01031 11.0184 3.24365 9.76523 3.24365Z",
      "fill": "#313131"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.78843 5.71469C7.63711 5.71469 7.48599 5.77262 7.37052 5.88808L6.63046 6.62794V4.06117V2.87897V0.614053C6.63046 0.287764 6.36584 0.0229492 6.03936 0.0229492C5.71287 0.0229492 5.44825 0.287764 5.44825 0.614053V2.87877V4.06098V6.66775L4.66819 5.88788C4.55273 5.77242 4.40141 5.71449 4.25028 5.71449C4.09916 5.71449 3.94784 5.77242 3.83237 5.88788C3.60145 6.11841 3.60145 6.49278 3.83237 6.72351L5.59721 8.48835C5.71267 8.60381 5.864 8.66115 6.01492 8.66115C6.0165 8.66115 6.01808 8.66115 6.01946 8.66115C6.02084 8.66115 6.02241 8.66115 6.02379 8.66115C6.17511 8.66115 6.32604 8.60381 6.4415 8.48835L8.20634 6.72351C8.43727 6.49278 8.43727 6.11841 8.20634 5.88788C8.09088 5.77262 7.93995 5.71469 7.78843 5.71469Z",
      "fill": "#313131"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_11862_137367"
    }
  }, [_c('rect', {
    attrs: {
      "width": "12",
      "height": "12",
      "fill": "white"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }