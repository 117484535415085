var render = function render(){
  var _vm$getDeclaration, _vm$getDeclaration2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('card-title', {
    attrs: {
      "title": "Параметры отчетности"
    }
  }), _c('div', {
    staticClass: "d-flex row-group"
  }, [_c('div', {
    staticClass: "select-block"
  }, [_c('label', [_vm._v("Отчетный период")]), _c('i-select', {
    staticStyle: {
      "max-width": "170px"
    },
    attrs: {
      "value": (_vm$getDeclaration = _vm.getDeclaration) === null || _vm$getDeclaration === void 0 ? void 0 : _vm$getDeclaration.quarter,
      "placeholder": "Отчетный период",
      "filterable": ""
    },
    on: {
      "input": _vm.inputPeriod
    }
  }, _vm._l(_vm.period, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_c('label', [_vm._v("Отчетный период")]), _vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "select-block"
  }, [_c('label', [_vm._v("Форма отчетности")]), _c('i-select', {
    staticStyle: {
      "max-width": "170px"
    },
    attrs: {
      "value": (_vm$getDeclaration2 = _vm.getDeclaration) === null || _vm$getDeclaration2 === void 0 ? void 0 : _vm$getDeclaration2.type,
      "placeholder": "Все магазины",
      "filterable": ""
    },
    on: {
      "input": _vm.inputType
    }
  }, _vm._l(_vm.form, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }