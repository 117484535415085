<template>
  <span class="title">{{ title }}</span>
</template>

<script>
  export default {
    name: 'CardTitle',
    props: ['title']
  }
</script>

<style scoped>
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #bcbcbc;
  }
</style>
