<template>
  <div class="send-block">
    <b class="title">Пиво и пивные напитки, первичная за 3 квартал 2023 </b>
    <div class="download">
      <p>Файл</p>
      <b-button
        variant="light"
        @click="download"
      >
        <document-svg style="margin-right: 6px" />
        <p style="padding-right: 10px; margin: 0">declaration_xml</p>
        <div class="vertival-line"></div>
        <download-svg style="margin-left: 10px" />
      </b-button>
    </div>
    <div class="line"></div>
    <div class="time-block">
      <p>Дата и время форматирования</p>
      <b>{{ convertData() }}</b>
    </div>
    <div class="line"></div>
    <div class="region">
      <p>Регион, принимающий декларацию</p>
      <div style="display: flex; align-items: center">
        <b>Свердловская область</b>
        <p class="region__change">Изменить</p>
      </div>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import DownloadSvg from '../../../../../public/img/icons/downloadSvg'
  import DocumentSvg from '../../../../../public/img/icons/documentSvg'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Send',
    components: { DocumentSvg, DownloadSvg },
    apollo: {
      GenerateDecalrationXml: {
        query: require('../../gql/GenerateDecalrationXml.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id
          }
        },
        result({ data }) {
          if (data?.GenerateDecalrationXml) this.xslString = data?.GenerateDecalrationXml
          this.loaded = true
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      entity: Object
    },
    data() {
      return {
        xslString: '',
        loaded: false
      }
    },
    computed: {
      ...mapGetters({
        getDeclaration: 'analytics/getDeclaration'
      })
    },
    methods: {
      convertData() {
        return formatDate(new Date(), 'datetime')
      },
      download() {
        const blob = new Blob([this.xslString], { type: 'application/xml' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const inn = this.entity.inn

        const quart = {
          first: '033',
          second: '063',
          third: '093',
          fourth: '003'
        }
        const period = quart[this.getDeclaration.quarter]
        const date = formatDate(new Date(), 'date').replaceAll('.', '')
        const uuid = this.$route.params.id?.toUpperCase()
        link.download = `08_${inn}_${period}_${date}_${uuid}.xml`
        link.click()
        URL.revokeObjectURL(link.href)
      }
    }
  }
</script>

<style lang="scss" scoped>
  p {
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #313131;
    margin-bottom: 6px;
  }

  b {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #313131;
  }

  .vertival-line {
    height: 100%;
    width: 1px;
    background: #e1e1e1;
    position: absolute;
    top: 0;
    right: 35px;
  }

  .region {
    padding: 22px 0;

    &__change {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #00a3ff !important;
      margin-bottom: 0;
      margin-left: 8px;
      transition: all 0.3s ease;

      &:active {
        transform: scale(0.93);
      }
    }
  }

  .download {
    padding: 22px 0;
  }

  .line {
    height: 1px;
    width: 100%;
    background: #e1e1e1;
  }

  .time-block {
    padding: 22px 0;
  }

  .title {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #313131;
  }

  .send-block {
    flex: 1;
    width: 100%;
    margin-top: 16px;
    padding: 32px 22px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    margin-bottom: 22px;
  }
</style>
